<template>
  <div class="header">
    <div class="inner">
      <a href="/" class="logo">
        <img
          src="@/assets/technine.png"
          alt="tech9"
          width="100%"
          height="65px"
          style="margin: 1%; object-fit: cover"
      /></a>
      <div class="main_menu">
        <div
          v-for="(data, index) in main"
          :key="index"
          class="item"
          @mouseover="subMenuOn"
        >
          <div class="item__name">
            <router-link :to="data.url">{{ data.title }}</router-link>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="sub_menu"> -->
    <div class="sub_menu" :style="{ display: isShow }" @mouseleave="subMenuOff">
      <div class="inner">        
        <div class="contentWrapper">
          <div class="wrap">
            <div
              class="content"
              v-for="(item, index) in sub.tech9"
              :key="index"
            >
              <div class="text">
                <router-link :to="item.url" class="link">{{
                  item.name
                }}</router-link>
                <div class="enDesc">
                  {{ item.enDesc }}
                </div>
              </div>
            </div>
          </div>
          <div class="wrap">
            <div
              id="test"
              class="content"
              v-for="(item, index) in sub.solutions"
              :key="index"
            >
              <div class="text">
                <div class="marker" v-if="item.marker">
                  <img src="@/assets/images/logo/logo_Innovative.png" width="
                  38" height="38" />
                </div>
                <router-link :to="item.url" v-html="item.name"></router-link>
                <div class="desc">
                  <!-- <p v-html="item.desc"></p> -->
                  <div>{{ item.desc }}</div>
                  <span>{{ item.desc0 }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="wrap">
            <div
              class="content"
              v-for="(item, index) in sub.info"
              :key="index"
            >
              <div class="text">
                <router-link :to="item.url" v-html="item.name"></router-link>
                <div class="desc">
                  <!-- <p v-html="item.desc"></p> -->
                  <div>{{ item.desc }}</div>
                  <span style="fontSize: 12.6px;">{{ item.desc0 }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="wrap">
            <div
              class="content"
              v-for="(item, index) in sub.cs"
              :key="index"
            >
              <div class="text">
                <a :href="item.url" target="_blank" v-html="item.name"></a>
                <div class="desc">
                  <!-- <p v-html="item.desc"></p> -->
                  <div>{{ item.desc }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isShow: 'none',
      main: [
        {
          title: 'TECH9',
          url: '/about',
        },
        {
          title: '솔루션',
          url: '/srds',
        },
        {
          title: '소식',
          url: '/news',
        },
        {
          title: '고객문의',
          url: '/contact',
        },
      ],
      sub: {
        tech9: [
          { name: '테크나인', enDesc: 'About Tech9', url: '/about' },
          {
            name: '연혁 및 주요성과',
            enDesc: 'Business & History',
            url: '/history',
          },
          { name: '파트너스', enDesc: 'Our Partners', url: '/partners' },
        ],
        solutions: [
          {
            name: '지능형 무선 위험(산사태 등) 감지 시스템',
            desc0: '[국토부 재난안전 혁신제품]',
            desc: '산사태, 낙석, 사면 무너짐 실시간 모니터링',
            marker: '@/assets/images/logo/logo_Innovative.png',
            url: '/srds',
          },
          {
            name: '지능형 산불감지 시스템',
            desc: '산불감지 및 확산방향 유추',
            url: '/swfm',
          },
          {
            name: '소나무 재선충병 AI 모니터링 시스템',
            desc: '친환경 AI 재선충 감염목 예찰',
            url: '/nematode',
          },
          {
            name: '기타 솔루션',
            desc: '자동관수제어, 비점오염 저감장치, 과수병 모니터링 등',
            url: '/etc',
          },
        ],
        info: [
          {
            name: '보도자료',
            desc0: 'with. 국가정보기간뉴스-NEWSRO',
            desc: "News",
            url: '/news'
          },
        ],
        cs: [
          {
            name: '구매하기 ⬈',
            desc: '혁신장터 바로가기',
            url: 'https://ppi.g2b.go.kr:8914/sm/dm/sch/searchGoodsDetail.do?invGdsIdntNo=00011743'
          },
          {
            name: '위치',
            desc: '찾아오시는 길',
            url: '/contact'
          }
        ]
      },
    };
  },
  methods: {
    subMenuOn() {
      this.isShow = 'block';
    },
    subMenuOff() {
      this.isShow = 'none';
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 9;
  background-color: rgba(255, 255, 255,0.88);
  .inner {
    display: flex;
    height: 70px;
    .main_menu {
      position: absolute;
      right: 0;
      display: flex;
      z-index: 1;
      .item {
        border-radius: 6px;
        .item__name {
          width: 210px;
          height: 70px;
          text-align: center;
          line-height: 70px;
          font-size: 1.2em;
          a {
            font-family: 'S-CoreDream-3Light';            
            color: #555555;
            text-decoration: none;
            font-weight: bold;
            &:hover {
              color: #23adc6;
              background-color: rgba(199, 0, 162, 0.095);
            }
          }
        }
      }
    }
  }
  .sub_menu {
    padding-top: 1.2%;
    background-color: rgba(255, 255, 255, 0.68);
    width: 100%;

    > .inner {
      height: 400px;
      .contentWrapper {
        position: absolute;
        right: 0;
        width: 840px + 215px;
        height: 400px;
        display: flex;
        .wrap {
          border-right: 1px dotted #d6d6d6;
          .content {
            position: relative;
            // margin-bottom: 5%;
            height: 88px;
            width: 215px;
            // border: 1px solid green;
            line-height: 100px;
            .text {
              text-align: center;
              font-size: 17px;
              .marker {
                position: absolute;
                left: 18px;
                top: 0;
                
              }
              > a {
                font-family: 'S-CoreDream-3Light';
                font-weight: 700;
                color: #333333;
                text-decoration: none; 
                &:hover {
                  color: #23adc6;
                }
              }
              .desc {
                /* engish description */
                font-size: 14px;
                font-family: 'S-CoreDream-3Light';
                > div {
                  /* solution description */
                  font-size: 14px;
                  font-family: 'S-CoreDream-3Light';
                  color: #696969;
                } 
                > span {
                  color: #1f9ab0;
                  font-family: 'NanumSquare';
                  // font-size: 14px;
                  font-weight: bold;
                }
              }

              .enDesc{
                font-size: 14px;
                color: #7d7d7d;
                font-family: 'S-CoreDream-3Light';
              }
            }
          }
        }
      }
    }
  }
}

#test {
  width: 215px + 200px;
}

</style>
